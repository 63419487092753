<template>
  <v-app>
    <v-main>
      <form>
        <v-container grid-list-md class="ghost">
          <v-row class="justify-space-between">
            <v-col cols="10" sm="3">
              <v-text-field
                label="Patient ID / Name"
                outlined
                class="iol-v-text-field"
                ref="first"
                v-model="inputs.patientID"
              />
            </v-col>
            <v-col cols="10" sm="3">
              <v-select
                label="Keratometric Index"
                outlined
                class="smooth iol-v-select"
                v-model="inputs.keratometricIndex"
                :items="$parent.keratometricItems"
                return-object
                required
              />
            </v-col>
            <v-col cols="10" sm="3">
              <v-select
                label="Biometer"
                :items="$parent.biometerItems"
                item-text="text"
                outlined
                class="smooth iol-v-select"
                v-model="inputs.biometer"
                return-object
                required
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-divider />
            </v-col>
          </v-row>
          <v-sheet v-bind:class="{ 'iol-right-v-sheet': inputs.side, 'iol-left-v-sheet': !inputs.side }">
            <v-row class="align-baseline">
              <v-col cols="2" md="5" class="switch-eye-label-left">
                <transition name="fade">
                  <div v-if="inputs.side">
                    <h1>OD</h1>
                  </div>
                </transition>
              </v-col>
              <v-spacer />
              <v-col cols="2" md="1">
                <v-switch
                  v-model="inputs.side"
                  class="switch-eye"
                  :messages="inputs.side ? 'switch to left eye' : 'switch to right eye'"
                />
              </v-col>
              <v-spacer />
              <v-col cols="2" md="5" class="switch-eye-label-right">
                <transition name="fade">
                  <div v-if="!inputs.side">
                    <h1>OS</h1>
                  </div>
                </transition>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-sheet class="iol-v-sheet">
                  <v-row>
                    <v-col cols="6" md="3">
                      <v-select
                        label="IOL Model"
                        :items="$parent.IOLModelItems"
                        item-text="text"
                        outlined
                        v-model="inputs.IOLModel"
                        return-object
                        required
                        class="smooth iol-v-select"
                        @change="IOLModelUpdated()"
                        @blur="IOLModelUpdated()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="6" md="3">
                      <v-text-field
                        label="A constant"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.aconst"
                        :error-messages="aconstErrors"
                        :disabled="inputs.is60MA"
                        :required="inputs.is60MA"
                        type="number"
                        step="0.01"
                        @input="$v.inputs.aconst.$touch()"
                        @blur="$v.inputs.aconst.$touch()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="12" md="4">
                      <v-text-field
                        label="Target refraction (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.target"
                        :error-messages="TargetErrors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.target.$touch()"
                        @blur="$v.inputs.target.$touch()"
                      />
                    </v-col>
                    <v-col cols="6" md="5">
                      <v-select
                        label="Vitreous type"
                        :items="$parent.vitreousItems"
                        item-text="type"
                        outlined
                        class="smooth iol-v-select"
                        v-model="inputs.vitreousType"
                        return-object
                        readonly
                        @change="vitreousTypeUpdated()"
                        @blur="vitreousTypeUpdated()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="6" md="5">
                      <v-tooltip bottom slot="append">
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            label="Custom vitreous"
                            outlined
                            class="iol-v-text-field"
                            v-model="inputs.nvit"
                            :error-messages="vitreousErrors"
                            required
                            :disabled="inputs.isVitreous"
                            type="number"
                            step="0.0001"
                            @input="$v.inputs.nvit.$touch()"
                            @blur="$v.inputs.nvit.$touch()"
                            v-bind="attrs"
                            v-on="on"
                          />
                        </template>
                        <span>Enter the refractive index of the material (ex. silicon oil)</span>
                      </v-tooltip>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="6" sm="5">
                      <v-text-field
                        label="AL (mm)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.AL"
                        :error-messages="ALErrors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.AL.$touch()"
                        @blur="$v.inputs.AL.$touch()"
                      />
                      <v-text-field
                        label="K1 (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.K1"
                        :error-messages="K1Errors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K1.$touch()"
                        @blur="$v.inputs.K1.$touch()"
                      />
                      <v-text-field
                        label="K2 (D)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.K2"
                        :error-messages="K2Errors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.K2.$touch()"
                        @blur="$v.inputs.K2.$touch()"
                      />
                      <v-text-field
                        label="ACD (mm)"
                        outlined
                        class="iol-v-text-field"
                        v-model="inputs.ACD"
                        :error-messages="ACDErrors"
                        required
                        type="number"
                        step="0.01"
                        @input="$v.inputs.ACD.$touch()"
                        @blur="$v.inputs.ACD.$touch()"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col cols="6" sm="6">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-text-field
                          label="LT (mm)"
                          outlined
                          class="iol-v-text-field"
                          v-model="inputs.LT"
                          :error-messages="LTErrors"
                          type="number"
                          step="0.01"
                          @input="$v.inputs.LT.$touch()"
                          @blur="$v.inputs.LT.$touch()"
                        />
                        <v-text-field
                          label="CCT (µm)"
                          outlined
                          class="iol-v-text-field"
                          v-model="inputs.CCT"
                          :error-messages="CCTErrors"
                          type="number"
                          step="0"
                          @input="$v.inputs.CCT.$touch()"
                          @blur="$v.inputs.CCT.$touch()"
                        />
                      </v-sheet>
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
              <v-col cols="12" md="6">
                <v-sheet class="iol-v-sheet">
                  <v-row>
                    <v-col cols="12" sm="12">
                      <v-sheet class="iol-opt-v-sheet">
                        <h3>Optional</h3><br />
                        <v-row class="justify-left">
                          <v-col cols="8" md="6" offset="1">
                            <v-select
                              label="Corneal refractive surgery"
                              :items="$parent.cornealLaserTypeItems"
                              item-text="text"
                              outlined
                              class="smooth iol-v-select"
                              v-model="inputs.cornealLaserType"
                              return-object
                              @change="cornealLaserTypeUpdate()"
                              @blur="cornealLaserTypeUpdate()"
                            />
                          </v-col>
                        </v-row>
                        <v-row class="justify-center">
                          <v-col cols="8" md="8">
                            <v-tooltip bottom slot="append">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  label="Pre-LVC anterior corneal radius (mm)"
                                  outlined
                                  class="iol-v-text-field"
                                  v-model="inputs.preLvcArc"
                                  :error-messages="preLvcArcErrors"
                                  :disabled="!inputs.isCornealLaser"
                                  type="number"
                                  step="0.01"
                                  @change="$v.inputs.preLvcArc.$touch()"
                                  @blur="$v.inputs.preLvcArc.$touch()"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>If available, enter the anterior corneal radius measured before laser vision correction, in millimeters</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                        <v-row class="justify-center">
                          <v-col cols="8" md="8">
                            <v-tooltip bottom slot="append">
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  label="Corrected ametropia (D)"
                                  outlined
                                  class="iol-v-text-field"
                                  v-model="inputs.laserAmount"
                                  :error-messages="laserAmountErrors"
                                  :disabled="!inputs.isCornealLaser"
                                  type="number"
                                  step="0.01"
                                  @change="$v.inputs.laserAmount.$touch()"
                                  @blur="$v.inputs.laserAmount.$touch()"
                                  v-bind="attrs"
                                  v-on="on"
                                />
                              </template>
                              <span>If available, enter the absolute value of the corrected ametropia, in diopters</span>
                            </v-tooltip>
                          </v-col>
                        </v-row>
                      </v-sheet>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="10" md="6">
                      <v-switch
                        label="Radial keratotomy"
                        hide-details
                        v-model="inputs.isRK"
                        @input="$v.inputs.isRK.$touch()"
                        @blur="$v.inputs.isRK.$touch()"
                      />
                    </v-col>
                    <v-col cols="10" md="6">
                      <v-switch
                        label="Non-physiological cornea"
                        hide-details
                        v-model="inputs.isNonPhysioCornea"
                        prepend-icon="info"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" color="primary">
                                info
                              </v-icon>
                            </template>
                          <span>If the anterior corneal radius is non-physiological, activate this option. It will remove the ARC from the IOL position predictors. NB: Activation is useless for post-LVC / post-RK eyes</span>
                        </v-tooltip>
                      </v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" md="3">
                      <v-switch
                        label="ICL"
                        hide-details
                        v-model="inputs.isICL"
                        prepend-icon="info"
                      >
                        <v-tooltip bottom slot="prepend">
                          <template v-slot:activator="{ on, attrs }">
                              <v-icon v-on="on" v-bind="attrs" color="primary">
                                info
                              </v-icon>
                            </template>
                          <span>If an Implantable Collamer Lens was implanted at the time of biometry, activate this option. It will adapt the AL measurement</span>
                        </v-tooltip>
                      </v-switch>
                    </v-col>
                  </v-row>
                  <v-row>
                    <br />
                  </v-row>
                  <v-row>
                    <v-col>
                      <br />
                    </v-col>
                  </v-row>
                </v-sheet>
              </v-col>
            </v-row>
          </v-sheet>
          <v-row>
            <v-col cols="12">
              <v-divider />
              <br />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn @click="submit" color="#3B4256" class="white--text" x-large>
                Calculate
              </v-btn>
            </v-col>
          </v-row>
          <br />
          <v-row>
            <v-col cols="12" class="text-center">
              <a @click="clear" :style="$parent.resetstyle">Reset</a>
            </v-col>
          </v-row>
        </v-container>
      </form>
    </v-main>
  </v-app>
</template>

<style>
.v-application .primary--text {
  color: #3b4256 !important;
  caret-color: #3b4256 !important;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import { validationMixin } from 'vuelidate'
import {
  required,
  requiredIf,
  minValue,
  between
} from 'vuelidate/lib/validators'
import { solve } from '@/graphql/queries/solve.gql'
import Logout from '@/components/Logout.vue'

let omitNull = obj => {
  let copy = { ...obj }
  Object.keys(copy)
    .filter(k => copy[k] === null)
    .forEach(k => delete copy[k])
  return copy
}

export default {
  mixins: [validationMixin],
  components: {
    Logout
  },
  validations () {
    return {
      inputs: {
        AL: { required, between: between(17, 40) },
        ACD: { required, between: between(1, 6) },
        K1: { required, between: between(30, 60) },
        K2: {
          required,
          between: between(30, 60),
          minValue: minValue(this.inputs.K1)
        },
        LT: { between: between(2.5, 8) },
        CCT: { between: between(350, 700) },
        target: { required, between: between(-4, 1.5) },
        aconst: {
          required: requiredIf(function (form) {
            return !this.inputs.is60MA
          }),
          between: between(116.5, 121) },
        preLvcArc: { between: between(5.5, 9.5) },
        laserAmount: { between: between(0.5, 12) },
        nvit: { required, between: between(1.2, 1.5) }
      }
    }
  },
  mounted () {
    if (this.$route.query.ocr) {
      this.$v.inputs.$touch()
    }
    this.inputs = { ...omitNull(this.inputs), ...omitNull(this.ginputs) }
  },
  data: () => {
    let ret = {
      inputs: {
        patientID: '',
        side: true,
        IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        aconst: 118.9,
        is60MA: false,
        isVitreous: true,
        isCornealLaser: false,
        isICL: false,
        cornealLaserType: { type: 'None', text: 'None' },
        isNonPhysioCornea: false,
        isRK: false,
        keratometricIndex: 1.3375,
        vitreousType: { type: 'Vitreous', value: 1.336 },
        nvit: 1.336,
        biometer: { type: 'Other', text: 'Other' },
        preLvcArc: '',
        laserAmount: '',
        target: 0
      },
      inputs_default: {
        patientID: '',
        side: true,
        IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
        aconst: 118.9,
        is60MA: false,
        isVitreous: true,
        isCornealLaser: false,
        isICL: false,
        cornealLaserType: { type: 'None', text: 'None' },
        isNonPhysioCornea: false,
        isRK: false,
        keratometricIndex: 1.3375,
        vitreousType: { type: 'Vitreous', value: 1.336 },
        nvit: 1.336,
        biometer: { type: 'Other', text: 'Other' },
        preLvcArc: '',
        laserAmount: '',
        target: 0
      }
    }
    // if (process.env.NODE_ENV !== 'production') {
    //   ret.inputs = {
    //     ...ret.inputs,
    //     IOLModel: { type: 'Finevision', text: 'Finevision', aconst: 118.9 },
    //     aconst: 118.9,
    //     AL: 23,
    //     ACD: 4,
    //     K1: 33,
    //     K2: 33,
    //     LT: 5,
    //     CCT: 500,
    //     target: 1,
    //     isCornealLaser: true,
    //     cornealLaserType: { type: 'Myopic', text: 'Myopic' },
    //     preLvcArc: 7,
    //     laserAmount: 9,
    //     isICL: true,
    //     isNonPhysioCornea: true,
    //     isRK: true,
    //     patientID: 'testid'
    //   }
    // }
    return ret
  },

  computed: {
    ...mapState(['appHeader', 'ginputs']),
    ALErrors () {
      const errors = []
      if (!this.$v.inputs.AL.$dirty) return errors
      !this.$v.inputs.AL.between && errors.push('value out of bounds')
      !this.$v.inputs.AL.required && errors.push('required')
      return errors
    },
    ACDErrors () {
      const errors = []
      if (!this.$v.inputs.ACD.$dirty) return errors
      !this.$v.inputs.ACD.between && errors.push('value out of bounds')
      !this.$v.inputs.ACD.required && errors.push('required')
      return errors
    },
    K1Errors () {
      const errors = []
      if (!this.$v.inputs.K1.$dirty) return errors
      !this.$v.inputs.K1.between && errors.push('value out of bounds')
      !this.$v.inputs.K1.required && errors.push('required')
      return errors
    },
    K2Errors () {
      const errors = []
      if (!this.$v.inputs.K2.$dirty) return errors
      !this.$v.inputs.K2.between && errors.push('value out of bounds')
      !this.$v.inputs.K2.required && errors.push('required')
      return errors
    },
    LTErrors () {
      const errors = []
      if (!this.$v.inputs.LT.$dirty) return errors
      !this.$v.inputs.LT.between && errors.push('value out of bounds')
      return errors
    },
    CCTErrors () {
      const errors = []
      if (!this.$v.inputs.CCT.$dirty) return errors
      !this.$v.inputs.CCT.between && errors.push('value out of bounds')
      return errors
    },
    TargetErrors () {
      const errors = []
      if (!this.$v.inputs.target.$dirty) return errors
      !this.$v.inputs.target.between && errors.push('value out of bounds')
      !this.$v.inputs.target.required && errors.push('required')
      return errors
    },
    aconstErrors () {
      const errors = []
      if (!this.$v.inputs.aconst.$dirty) return errors
      !this.$v.inputs.aconst.between && errors.push('value out of bounds')
      !this.$v.inputs.aconst.required && errors.push('required')
      return errors
    },
    preLvcArcErrors () {
      const errors = []
      if (!this.$v.inputs.preLvcArc.$dirty) return errors
      !this.$v.inputs.preLvcArc.between && errors.push('value out of bounds')
      return errors
    },
    laserAmountErrors () {
      const errors = []
      if (!this.$v.inputs.laserAmount.$dirty) return errors
      !this.$v.inputs.laserAmount.between && errors.push('value out of bounds')
      return errors
    },
    vitreousErrors () {
      const errors = []
      if (!this.$v.inputs.nvit.$dirty) return errors
      !this.$v.inputs.nvit.between && errors.push('value out of bounds')
      !this.$v.inputs.nvit.required && errors.push('required')
      return errors
    }
  },

  methods: {
    IOLModelUpdated () {
      if (this.inputs.IOLModel.type === '60MA') {
        this.inputs.is60MA = true
      } else {
        this.inputs.is60MA = false
      }
      this.inputs.aconst = this.inputs.IOLModel.aconst
    },
    cornealLaserTypeUpdate () {
      if (this.inputs.cornealLaserType.type === 'None') {
        this.inputs.isCornealLaser = false
        this.inputs.preLvcArc = ''
        this.inputs.laserAmount = ''
      } else {
        this.inputs.isCornealLaser = true
      }
    },
    vitreousTypeUpdated () {
      this.inputs.nvit = this.inputs.vitreousType.value
      if (this.inputs.vitreousType.type === 'Vitreous') {
        this.inputs.isVitreous = true
      } else {
        this.inputs.isVitreous = false
      }
    },
    focusFirstError (component = this) {
      if (component.hasError) {
        component.focus()
        return true
      }

      let focused = false

      component.$children.some(childComponent => {
        focused = this.focusFirstError(childComponent)
        return focused
      })

      return focused
    },
    submit () {
      this.$v.inputs.$touch()
      this.$nextTick(() => {
        if (this.focusFirstError()) {
          return
        }
        Object.keys(this.inputs).forEach(
          k => (this.inputs[k] = this.inputs[k] === '' ? null : this.inputs[k])
        )
        const inputs = { ...omitNull(this.inputs) }
        this.$store.dispatch('updateInputs', this.inputs)

        inputs['IOLModel'] = inputs['IOLModel'].type // send IOLModel type
        inputs['biometer'] = inputs['biometer'].type // send biometer type
        inputs['cornealLaserType'] = inputs['cornealLaserType'].type // send cornealLaserType type

        delete inputs['patientID'] // do not send patientID
        delete inputs['vitreousType'] // do not send vitreousType
        delete inputs['isVitreous'] // do not send isVitreous
        delete inputs['is60MA'] // do not send vitreousType

        // delete inputs from second
        delete inputs['isSecond']
        delete inputs['AL2']
        delete inputs['K12']
        delete inputs['K22']
        delete inputs['ACD2']
        delete inputs['LT2']
        delete inputs['CCT2']
        delete inputs['power2']
        delete inputs['SE2']
        delete inputs['aconst2']
        delete inputs['isCornealLaser2']
        delete inputs['cornealLaserType2']
        delete inputs['laserAmount2']
        delete inputs['isICL2']
        delete inputs['preLvcArc2']

        this.$apollo
          .query({
            query: solve,
            variables: {
              inputs
            }
          })
          .then(result => {
            this.$store.dispatch('setResults', result.data.solve)
            this.$router.push('result_complex')
          })
          .catch(e => {
            this.$raven.captureException(e)
          })
      })
    },
    clear () {
      Object.keys(this.inputs).forEach(k => (this.inputs[k] = this.inputs_default[k] !== null ? this.inputs_default[k] : null))
      this.$refs.first.focus()
    },
    ...mapActions(['updateInputs', 'setResults', 'setAuthUser'])
  }
}
</script>
